.main {
  display: absolute;
  /* margin-top: 200px; */
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sector {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-bottom: 40px;
  background-color: rgba(232, 232, 232, 0.275);
  border-radius: 5px;
}
