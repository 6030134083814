@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1d1642;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  background: rgba(241, 241, 241, 0.5);
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%), 0 3px 5px 0 rgb(0 0 0 / 19%);
  width: 100%;
  z-index: 200;
  min-height: 65px;
  position: fixed;
}

.wrapper.scrolled {
  background-color: #f1f1f1;
}

.wrapper nav {
  position: relative;
  display: flex;
  max-width: calc(100% - 20px);
  margin: 0 auto;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  margin-right: 60px;
  margin-left: 20px;
  /* margin-top: 20px; */
  padding: 20px 20px;
}
nav .content {
  display: flex;
  align-items: center;
}
nav .content .links {
  margin-left: 80px;
  display: flex;
}
.logo {
  display: flex;
  align-items: center;
}

#logo {
  margin: 0 15px;
}

.logo-text {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  /* align-items: center; */
  text-decoration: none;
}
.content .logo a {
  color: rgb(0, 0, 0);
  font-size: 30px;
  font-weight: 600;
  margin-right: 10px;
  width: 100%;
  justify-content: center;
  display: flex;
}
.content .links li {
  list-style: none;
  line-height: 70px;
}
.content .links li a,
.content .links li label {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 400;
  padding: 9px 17px;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-decoration: none;
}
.content .links li label {
  display: none;
}
.content .links li a:hover {
  background: #f1f1f1;
  color: #012970;
}
.content .links li label:hover {
  background: #f1f1f1;
  color: #012970;
}
.wrapper .search-icon,
.wrapper .menu-icon {
  color: rgb(0, 0, 0);
  font-size: 18px;
  cursor: pointer;
  line-height: 70px;
  width: 70px;
  text-align: center;
}
.wrapper .menu-icon {
  display: none;
}
.wrapper input[type='checkbox'] {
  display: none;
}
/* Dropdown Menu code start */
.content .links ul {
  position: absolute;
  background: #ffffff;
  top: 80px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.content .links li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}
.content .links ul li a {
  display: block;
  width: -webkit-fill-available;
  line-height: 30px;
  border-radius: 0px !important;
}
.content .links ul ul {
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}
.content .links ul li {
  position: relative;
}
.content .links ul li:hover ul {
  top: 0;
}
/* Responsive code start */
@media screen and (max-width: 1445px) {
  .wrapper nav {
    max-width: 100%;
    padding: 0 20px;
  }
  nav .content .links {
    margin-left: 30px;
  }
  .content .links li a {
    padding: 8px 13px;
  }
  .logo {
    font-size: small;
  }
}

@media screen and (max-width: 1300px) {
  .wrapper .menu-icon {
    display: block;
  }
  .wrapper #show-menu:checked ~ .menu-icon i::before {
    content: '\f00d';
  }
  nav .content .links {
    display: block;
    position: fixed;
    background: #ffffff;
    height: 100%;
    width: 100%;
    top: 70px;
    left: -100%;
    margin-left: 0;
    max-width: 350px;
    max-height: -webkit-fill-available;
    overflow-y: auto;
    padding-bottom: 100px;
    transition: all 0.4s ease;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  nav #show-menu:checked ~ .content .links {
    left: 0%;
  }
  .content .links li {
    margin: 15px 20px;
  }
  .content .links li a,
  .content .links li label {
    line-height: 40px;
    font-size: 14px;
    display: block;
    padding: 8px 18px;
    cursor: pointer;
  }
  .content .links li label {
    text-align-last: justify;
  }
  .content .links li a.desktop-link {
    display: none;
  }
  /* dropdown responsive code start */
  .content .links ul,
  .content .links ul ul {
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }
  .content .links #show-home:checked ~ ul,
  .content .links #show-academics:checked ~ ul,
  .content .links #show-students:checked ~ ul,
  .content .links #show-recruiter:checked ~ ul,
  .content .links #show-forms:checked ~ ul,
  .content .links #show-cdc-team:checked ~ ul,
  .content .links #show-login:checked ~ ul,
  .content .links #show-auth-options:checked ~ ul {
    max-height: 100vh;
  }
  .content .links ul {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 19%);
  }
  .content .links ul li {
    margin: 7px 20px;
  }
  .content .links ul li a {
    font-size: 14px;
    line-height: 30px;
    border-radius: 5px !important;
  }
  .logo-text {
    /* font-size: 2vw; */
  }
}
@media screen and (min-width: 900px) {
  .content .links ul {
    width: 200px;
  }
}
@media screen and (max-width: 400px) {
  .wrapper nav {
    padding: 0 10px;
  }
  .content .logo a {
    font-size: 27px;
  }
  .logo-text {
    font-size: 2vw;
  }
}

.HeroSection_hero_container__3-9d5 {
  background: url(/static/media/cdc_pic_2.17b39509.webp) center/cover no-repeat;
  /* linear-gradient( */
  /* to top, */
  /* #070f29da, */
  /* rgba(25, 34, 61, 0.623) 20%, */
  /* rgba(84, 103, 161, 0.39), */
  /* rgba(255, 255, 255, 0) */
  /* ) */
  background-attachment: fixed;
  background-size: cover;
  height: 95vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 1000px 0 0px rgba(0, 0, 0, 0);
  object-fit: contain;
}

.HeroSection_hero_container__3-9d5 div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HeroSection_hero_container__3-9d5 img {
  width: 120px;
  height: 120px;
}
.HeroSection_hero_container__3-9d5 h1 {
  color: rgb(235, 232, 232);
  font-size: 3rem;
  /* margin-top: -30px; */
  /* font-family: 'Inter', sans-serif; */
  text-align: center;
  font-weight: 400;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.HeroSection_hero_container__3-9d5 span {
  /* margin-top: 18px; */
  color: rgb(255, 253, 253);
  font-size: 2rem;
  /* font-family: 'Inter', sans-serif; */
  text-align: center;
  font-weight: 300;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.HeroSection_home_section_btx__FLd7F {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.HeroSection_home_section_btx__FLd7F a {
  font-size: 1em;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
  border-radius: 25px;
  border: 1px solid white;
  width: auto;
  text-align: center;
  transition: background-color 0.3s ease;
}

.HeroSection_home_section_btx__FLd7F a:nth-child(1) {
  background-color: #9e6531;
  color: white;
}

.HeroSection_home_section_btx__FLd7F a:nth-child(1):hover {
  background-color: #865527;
  color: white;
}

.HeroSection_home_section_btx__FLd7F a:nth-child(2) {
  background-color: white;
  color: #9e6530;
}

.HeroSection_home_section_btx__FLd7F a:nth-child(2):hover {
  background-color: rgb(235, 227, 227);
}

.HeroSection_hero_btns__2kRPm {
  margin-top: 32px;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.HeroSection_hero_container__3-9d5 .HeroSection_hero_btns__2kRPm .HeroSection_btn__2e4Lt {
  margin: 25px;
  height: 70px;
  padding: 8px 40px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 22px;
  border-radius: 25px;
}

.HeroSection_logo__rxS4y {
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  flex-wrap: wrap;
  padding: 10px;
}

.HeroSection_iitj_logo__2qzI9 {
  width: 180px;
  height: 190px;
  padding-left: 10px;
}

.HeroSection_cdc_logo__3L4e5 {
  width: 190px;
  height: 190px;
  padding-left: 10px;
}

.HeroSection_hero_btns__2kRPm .HeroSection_btn__2e4Lt:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media screen and (min-width: 420px) {
  .HeroSection_logo__rxS4y {
    background: #f0f8ffa3;
    border-radius: 40px;
  }
}

@media screen and (max-width: 970px) {
  .HeroSection_hero_container__3-9d5 > h1 {
    font-size: 70px;
    margin-top: -60px;
    text-align: center;
  }
}

@media screen and (max-width: 868px) {
  .HeroSection_hero_container__3-9d5 > h1 {
    font-size: 50px;
    margin-top: -10px;
    text-align: center;
  }

  .HeroSection_hero_container__3-9d5 > p {
    font-size: 30px;
  }

  .HeroSection_btn_mobile__1nBQI {
    display: block;
    text-decoration: none;
  }

  .HeroSection_btn__2e4Lt {
    width: 85%;
    font-size: 20px;
  }

  .HeroSection_logo__rxS4y img {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 420px) {
  .HeroSection_hero_container__3-9d5 > h1 {
    font-size: 32px;
    margin-top: -5px;
    text-align: center;
  }

  .HeroSection_hero_container__3-9d5 > p {
    font-size: 21px;
  }

  .HeroSection_btn_mobile__1nBQI {
    display: block;
    text-decoration: none;
  }

  .HeroSection_btn__2e4Lt {
    width: 85%;
  }

  .HeroSection_hero_btns__2kRPm .HeroSection_btn__2e4Lt {
    margin: 20px;
    height: 50px;
    padding: 8px 20px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    font-size: 15px;
    border-radius: 25px;
  }

  .HeroSection_logo__rxS4y img {
    margin-top: -5px;
    margin-bottom: -60px;
    width: 100px;
    height: 100px;
  }
  .HeroSection_iitj_logo__2qzI9 {
    background: #f0f8ffa3;
    padding: 14px;
  }
  .HeroSection_cdc_logo__3L4e5 {
    background: #f0f8ffa3;
    padding: 14px;
  }
}

@media screen and (max-width: 330px) {
  .HeroSection_hero_container__3-9d5 > h1 {
    font-size: 25px;
    margin-top: 10px;
  }

  .HeroSection_hero_container__3-9d5 > p {
    font-size: 16px;
  }

  .HeroSection_hero_btns__2kRPm .HeroSection_btn__2e4Lt {
    font-size: 14px;
  }
}

.AboutUs_aboutus-container__D7WMZ {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -25px;
  border-radius: 20px;
  padding: 30px 0;
}

.AboutUs_textContainer__2BS2I {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.5;
  max-width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0px 16px 32px 0px rgba(233, 238, 242, 0.4);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.AboutUs_textContainer__2BS2I:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.AboutUs_aboutUsTitle__1VN7n {
  font-size: 40px;
  margin-bottom: 1rem;
  text-align: center;
  color: rgb(29, 22, 66);
  /* font-family: 'Lato', sans-serif; */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 600px) {
  .AboutUs_textContainer__2BS2I {
    font-size: '1rem';
    width: 90%;
    padding: 20px;
  }
}

.carousel-wrapper {
  width: 700px;
  height: 400px;
  margin-inline: auto;
  border-color: rgb(0, 0, 0);
}

.slick-slide img {
  height: 400px;
  width: 700px;
  display: inline;
}

.slick-dots {
  bottom: 30px;
}

.slick-dots li {
  background: aliceblue;
  width: 8px;
  height: 8px;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  border-radius: 20%;
}

.slick-dots .slick-active {
  background: rgb(24, 124, 211);
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
}

.slick-dots li button:before {
  display: none;
}

.slick-prev {
  left: 25px;
  z-index: 2;
}

.slick-next {
  right: 25px;
}

.carousel_container {
  justify-content: center;
  display: contents;
  max-width: 700px;
}

.carousel_container .carousel_grid {
  height: auto;
  max-width: 100%;
}

.MuiPaper-elevation1 {
  width: 700px;
}

@media screen and (max-width: 760px) {
  .MuiPaper-elevation1 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .slick-slide img {
    height: 40vh;
    width: 100%;
  }
  .carousel-wrapper {
    width: 100%;
    height: 40vh;
  }
  .slick-dots {
    bottom: 10px;
  }

  .slick-dots li {
    width: 5px;
    height: 5px;
  }

  .slick-dots .slick-active {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
  }
}

.image_placeholder {
  background-color: #ebe4e4;
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    background-color: #ebe4e4;
  }
  50% {
    background-color: #b1a4a4;
  }
  100% {
    background-color: #ebe4e4;
  }
}

@keyframes pulse {
  0% {
    background-color: #ebe4e4;
  }
  50% {
    background-color: #b1a4a4;
  }
  100% {
    background-color: #ebe4e4;
  }
}

.container-Overview {
  margin-top: 6em;
}

@media (min-width: 1100px) {
  .container-Overview {
    display: flex;
    justify-content: space-around;
  }
  .box-1 {
    width: inherit;
    flex: 7 1;
    margin-top: 20px;
  }
  .box-2 {
    flex: 3 1;
    margin-top: 20px;
  }
}
@media (max-width: 1100px) {
  .container-Overview {
    justify-content: center;
  }
  .box-1 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .box-2 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

/* .root {
  max-width: 600px;
  height: auto;
} */

.PastRecruiters_slider__2YuGR {
  width: 90%;
  margin: 0 auto;
}
.PastRecruiters_gridContainer__3k52T {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.PastRecruiters_gridItem__2xHzl {
  margin: 10px;
  width: calc(10% - 20px);
}

.PastRecruiters_PR_image__IokZ6 {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PastRecruiters_PR_image__IokZ6 img {
  box-shadow: 0px 8px 16px 0px rgba(233, 238, 242, 0.2);
  border-radius: 5px;
  transition: 0.4s;
  height: 200px;
  width: 110px;
  text-align: center;
  object-fit: contain;
}

.PastRecruiters_PR_image__IokZ6:hover {
  cursor: pointer;
  /* transition: 0.4s;
  box-shadow: 0 4px 6px 0 rgb(1, 41, 112, 0.16),
    0 6px 15px 0 rgb(1, 41, 112, 0.16);
  transform: scale(1.08); */
}

/* .slick-prev:before {
  float: right;
}
.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  color: rgba(75, 74, 74, 0.856);
  opacity: 0.2;
} */

@media screen and (max-width: 1100px) {
  .PastRecruiters_gridItem__2xHzl {
    width: calc(10% - 20px);
  }
}

@media screen and (max-width: 1000px) {
  .PastRecruiters_gridItem__2xHzl {
    width: calc(20% - 20px);
  }
}

@media screen and (max-width: 600px) {
  .PastRecruiters_gridItem__2xHzl {
    width: calc(50% - 20px);
  }
}

.C3MemberCard_teamContainer__2tryq {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.C3MemberCard_designation__PXjYV {
  font-size: 1.25rem;
  color: rgb(28, 26, 26);
  text-align: center;
  margin: 0.2rem 0;
}

.C3MemberCard_subDesignation__2DtRK {
  font-size: 1rem;
  color: rgb(24, 23, 23);
  margin: 0.2rem 0;
}

/* .profileImage {
  margin: 1rem 0;
  width: 200px;
  height: 200px;
  border-radius: 0.3rem;
} */

/* .cardContent {
  padding: 0.2rem;
  color: #012970;
  text-align: center;
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

.department {
  text-align: center;
  font-size: 1rem;
  color: #012970;
  margin: 0.5rem 0 0.9rem 0;
  padding: 0 2rem;
} */

.C3MemberCard_cardFooter__3acjk {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.7rem;
  margin: 0 auto 0;
  width: 15.5rem;
}

.C3MemberCard_email__1J3Jk {
  font-size: 1rem;
  color: #383cc1;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
}

.C3MemberCard_email__1J3Jk:hover {
  color: #012970;
}

.C3MemberCard_teamCard__1LHIL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 16px;
  margin: auto;
  box-shadow: 0px 8px 16px 0px rgba(120, 120, 0, 0.1);
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  height: 380px;
  width: 240px;
}

.C3MemberCard_teamCard__1LHIL:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.C3MemberCard_imageContainer__2P988 {
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 16px;
}

.C3MemberCard_profileImage__2xBCA {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.C3MemberCard_cardContent__2frt5 {
  text-align: center;
}

.C3MemberCard_department__1_5o2 {
  font-size: 1.2rem;
  color: #666;
  margin-top: 8px;
}

.badge-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 4rem 0;
}

.ribbon {
  position: absolute;
  width: 100%;
  height: 100px;
  background-color: #9e6531;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}

.NotFoundPage_error__s4Jv7 {
  position: relative;
  height: 100vh;
}

.NotFoundPage_notFound__3JIev {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 460px;
  width: 100%;
  text-align: center;
}

.NotFoundPage_brokenLink__3YcWZ {
  position: relative;
  width: 11rem;
  height: 11rem;
  margin: 0 auto 4rem;
}

.NotFoundPage_brokenLink__3YcWZ > div:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffa200;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 5px dashed #000;
  border-radius: 0.5rem;
}

.NotFoundPage_brokenLink__3YcWZ > h1 {
  margin: 0;
  font-weight: 700;
  font-size: 5rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  height: 3rem;
  line-height: 3rem;
}

.NotFoundPage_notFound__3JIev > h2 {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.NotFoundPage_notFound__3JIev > p {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0 1rem;
}

.NotFoundPage_homePage__3Eu4V {
  margin-top: 1rem;
  height: 100px;
}

.NotFoundPage_homeButton__27gS4 {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #012970;
  color: #012970;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}

.NotFoundPage_homeButton__27gS4:hover {
  background-color: #012970;
  color: #fff;
}

.ProgramCard_program__18cbK {
  width: 100%;
  text-align: center;
  font-weight: 550;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ProgramCard_program__18cbK:hover {
  color: #012970;
}

.ProgramCard_departments__3oA_K > div {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProgramCard_department__2ZEIo {
  width: 100%;
  margin: 0 0 0.5rem 0;
  color: black;
  -webkit-text-decoration: underline 0.1rem white;
          text-decoration: underline 0.1rem white;
  transition: text-underline-offset 500ms, -webkit-text-decoration-color 500ms;
  transition: text-decoration-color 500ms, text-underline-offset 500ms;
  transition: text-decoration-color 500ms, text-underline-offset 500ms, -webkit-text-decoration-color 500ms;
}

.ProgramCard_department__2ZEIo:hover {
  color: #012970;
  -webkit-text-decoration-color: #012970;
          text-decoration-color: #012970;
  text-underline-offset: 0.3rem;
}

.Programs_heading__24yq7 {
  font-size: 1.8rem;
  color: #fff;
  background-color: #012970;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.CourseHighlights_heading__ngbyG {
  font-size: 1.8rem;
  color: #fff;
  background-color: #012970;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.CourseHighlights_bTech__AQ9gC,
.CourseHighlights_mTech__2dKBX,
.CourseHighlights_mSc__2-R_C {
  font-size: 1.8rem;
  color: #012970;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CourseHighlights_hr__35MQD {
  margin: 1rem auto;
  width: 20rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.CourseCard_department__1-jqo {
  width: 100%;
  text-align: center;
  font-weight: 550;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.CourseCard_department__1-jqo:hover {
  color: #012970;
}

.CourseCard_description__3Zts1 {
  font-size: '1rem';
  color: 'black';
}

.CourseCard_description__3Zts1 > p {
  margin-top: 0;
}

.CourseCard_brochure__29gbW {
  width: 100%;
  text-align: center;
  margin: 0 auto 0.5rem auto;
  color: black;
  -webkit-text-decoration: underline 0.1rem white;
          text-decoration: underline 0.1rem white;
  transition: text-underline-offset 500ms, -webkit-text-decoration-color 500ms;
  transition: text-decoration-color 500ms, text-underline-offset 500ms;
  transition: text-decoration-color 500ms, text-underline-offset 500ms, -webkit-text-decoration-color 500ms;
}

.CourseCard_brochure__29gbW > i {
  margin-left: 0.2rem;
}

.CourseCard_brochure__29gbW:hover {
  color: #012970;
  -webkit-text-decoration-color: #012970;
          text-decoration-color: #012970;
  text-underline-offset: 0.3rem;
}

.Brochure_heading__1JUKE {
  font-size: 1.8rem;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.Brochure_jaf__2jY1T {
  text-align: center;
  margin: 2rem 0rem;
  padding: 1rem;
}

.Brochure_download__3XCxu {
  margin: 1rem auto;
}

.Brochure_download__3XCxu > a {
  font-weight: 500;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
}

.Brochure_iframe__3zvmp {
  margin: 2rem auto;
}

@media only screen and (max-width: 600px) {
  .Brochure_download__3XCxu > a {
    font-size: 1rem;
  }
  .Brochure_iframe__3zvmp {
    width: 95%;
    height: 60vh;
  }
}

@media only screen and (min-width: 600px) {
  .Brochure_download__3XCxu > a {
    font-size: 1rem;
  }
  .Brochure_iframe__3zvmp {
    width: 90%;
    height: 100vh;
  }
}

@media only screen and (min-width: 768px) {
  .Brochure_download__3XCxu > a {
    font-size: 1rem;
  }
  .Brochure_iframe__3zvmp {
    width: 80%;
    height: 100vh;
  }
}

@media only screen and (min-width: 992px) {
  .Brochure_download__3XCxu > a {
    font-size: 1.2rem;
  }
  .Brochure_iframe__3zvmp {
    width: 70%;
    height: 100vh;
  }
}

@media only screen and (min-width: 1200px) {
  .Brochure_download__3XCxu > a {
    font-size: 1.2rem;
  }
  .Brochure_iframe__3zvmp {
    width: 60%;
    height: 100vh;
  }
}

.PastRecruiters_list-type3__3r13Z {
  margin: 50px;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.PastRecruiters_list-type3__3r13Z div {
  float: left;
  height: 35px;
  line-height: 35px;
  position: relative;
  font-size: 15px;
  margin-bottom: 12px;
  font-family: 'Roboto', sans-serif;
  transition: background-color 1.5s ease;
}
.PastRecruiters_list-type3__3r13Z div {
  padding: 0 60px 0 12px;
  margin: 8px;
  background: #012970;
  color: #fff;
  text-decoration: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.PastRecruiters_list-type3__3r13Z div:before {
  content: '';
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent #0089e0 transparent transparent;
  border-style: solid;
  border-width: 18px 12px 18px 0;
}

.PaperHeader_heading__1EgvU {
  font-size: 1.8rem;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
  background-color: #012970;
}

.JAF_heading__2yH2i {
  font-size: 1.8rem;
  color: white;
  background: #012970;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.JAF_jaf__1aKB4 {
  text-align: center;
  margin: 2rem 0rem;
  padding: 1rem;
}

.JAF_download__1eTz- {
  margin: 1rem auto;
  padding: 0 1rem;
}

.JAF_download__1eTz- > a {
  font-weight: 500;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
}

.JAF_iframe__2dqgb {
  margin: 2rem auto;
}

@media only screen and (max-width: 600px) {
  .JAF_download__1eTz- > a {
    font-size: 1rem;
  }
  .JAF_iframe__2dqgb {
    width: 95%;
    height: 60vh;
  }
}

@media only screen and (min-width: 600px) {
  .JAF_download__1eTz- > a {
    font-size: 1rem;
  }
  .JAF_iframe__2dqgb {
    width: 90%;
    height: 100vh;
  }
}

@media only screen and (min-width: 768px) {
  .JAF_download__1eTz- > a {
    font-size: 1rem;
  }
  .JAF_iframe__2dqgb {
    width: 80%;
    height: 100vh;
  }
}

@media only screen and (min-width: 992px) {
  .JAF_download__1eTz- > a {
    font-size: 1.2rem;
  }
  .JAF_iframe__2dqgb {
    width: 70%;
    height: 100vh;
  }
}

@media only screen and (min-width: 1200px) {
  .JAF_download__1eTz- > a {
    font-size: 1.2rem;
  }
  .JAF_iframe__2dqgb {
    width: 60%;
    height: 100vh;
  }
}

.IAF_heading__3Isu_ {
  font-size: 1.8rem;
  color: white;
  background: #012970;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.IAF_iaf__3dRJ4 {
  text-align: center;
  margin: 2rem 0rem;
  padding: 1rem;
}

.IAF_download__3YhPs {
  margin: 1rem auto;
}

.IAF_download__3YhPs > a {
  font-weight: 500;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
}

.IAF_iframe__2e1M- {
  margin: 2rem auto;
}

@media only screen and (max-width: 600px) {
  .IAF_download__3YhPs > a {
    font-size: 1rem;
  }
  .IAF_iframe__2e1M- {
    width: 95%;
    height: 60vh;
  }
}

@media only screen and (min-width: 600px) {
  .IAF_download__3YhPs > a {
    font-size: 1rem;
  }
  .IAF_iframe__2e1M- {
    width: 90%;
    height: 100vh;
  }
}

@media only screen and (min-width: 768px) {
  .IAF_download__3YhPs > a {
    font-size: 1rem;
  }
  .IAF_iframe__2e1M- {
    width: 80%;
    height: 100vh;
  }
}

@media only screen and (min-width: 992px) {
  .IAF_download__3YhPs > a {
    font-size: 1.2rem;
  }
  .IAF_iframe__2e1M- {
    width: 70%;
    height: 100vh;
  }
}

@media only screen and (min-width: 1200px) {
  .IAF_download__3YhPs > a {
    font-size: 1.2rem;
  }
  .IAF_iframe__2e1M- {
    width: 60%;
    height: 100vh;
  }
}

.ContactDetails_email__3kpxt {
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  margin: 0.1rem 0;
}

.ContactDetails_phone__3Cv6J {
  font-size: 1.2rem;
  color: black;
  display: flex;
  justify-content: flex-start;
  margin: 0.1rem 0;
}

.ReachUs_reachUs__1oA9O {
  font-size: 30px;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.ReachUs_content__3_KnW {
  margin: 2rem 0rem;
  padding: 0.5rem 0rem;
}

.ContactCard_contactCard__1yZii {
  width: 290px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 1rem;
  margin: auto;
  background-color: rgb(251, 251, 251);
  transition: box-shadow 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.ContactCard_contactCard__1yZii:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.ContactCard_cardHeader__3Fu8s {
  padding: 0.5rem;
  /* background-color: #012970; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
  font-style: italic;
}

.ContactCard_designation__1BXbK {
  font-size: 1.25rem;
  color: rgb(21, 20, 20);
  text-align: center;
  margin: 0.2rem 0;
}

.ContactCard_subDesignation__3CVix {
  font-size: 0.9rem;
  color: rgb(19, 18, 18);
  margin: 0.2rem 0;
}

.ContactCard_profileImage__BfUCA {
  margin: 1rem 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.ContactCard_cardContent__2ONbn {
  padding: 0.2rem;
  color: #0e0e0f;
  text-align: center;
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
  font-style: italic;
}

.ContactCard_footer__Ut8zl {
  padding: 0.7rem;
  margin: 0rem auto;
  width: 15rem;
}

.ContactCard_email__M_S7C {
  font-size: 0.9rem;
  color: #383cc1;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0.5rem;
}

.ContactCard_email__M_S7C:hover {
  color: #121414;
}

.ContactCard_phone__ky_j9 {
  font-size: 0.9rem;
  color: #383cc1;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.ContactCard_phone__ky_j9:hover {
  color: #080909;
}

.ContactCard_social__2BXv5 {
  height: 2rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0.5rem 0 0 0;
}

.ContactCard_github__2putz {
  color: black;
  margin: 0 0.5rem;
  width: 1.8rem;
  height: 1.8rem;
  text-decoration: none;
}

.ContactCard_linkedin__1yOnu {
  color: #0072b1;
  margin: 0 0.5rem;
  width: 2.2rem;
  height: 2.2rem;
  text-decoration: none;
}

.PlacementTeam_heading__3iI3b {
  font-size: 1.8rem;
  color: white;
  background: #012970;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.PlacementTeam_members__3G1vG {
  font-size: 1.65rem;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PlacementTeam_hr__3H5F5 {
  margin: 1rem auto;
  width: 20rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.StudentLogin_heading__3_Qh9 {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 0;
}

.StudentLogin_form__3yfyy {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media all and (min-width: 1200px) {
  .StudentLogin_Login__9QtJm {
    margin: 10% 33%;
    border-radius: 0.6rem;
    padding: 2rem;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.6);
  }
  .StudentLogin_Loginform__2HlxI {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .StudentLogin_Loginform__2HlxI:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .StudentLogin_showPasswordBtn__3-N_9 {
    -webkit-transform: translate(81%, -165%);
            transform: translate(81%, -165%);
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .StudentLogin_Login__9QtJm {
    margin: 20% 20%;
    border-radius: 0.6rem;
    padding: 2rem;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .StudentLogin_Loginform__2HlxI {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .StudentLogin_Loginform__2HlxI:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .StudentLogin_showPasswordBtn__3-N_9 {
    -webkit-transform: translate(81%, -165%);
            transform: translate(81%, -165%);
  }
}

@media (max-width: 700px) {
  .StudentLogin_Login__9QtJm {
    margin: 40% 3%;
    border-radius: 0.6rem;
    padding: 5%;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .StudentLogin_Loginform__2HlxI {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .StudentLogin_Loginform__2HlxI:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .StudentLogin_showPasswordBtn__3-N_9 {
    -webkit-transform: translate(79%, -145%);
            transform: translate(79%, -145%);
  }
}

.StudentLogin_loginButton__1AOEY {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #012970;
  color: #fff;
  background-color: #012970;
  cursor: pointer;
}

.StudentLogin_loginButton__1AOEY:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.StudentLogin_clearButton__8jnFw {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #012970;
  color: #012970;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}

.StudentLogin_clearButton__8jnFw:hover {
  background-color: #012970;
  color: #fff;
}

.StudentLogin_error__2sUVd {
  position: absolute;
  font-size: medium;
  color: orangered;
}

.StudentLogin_Gbutton__3QEI6 {
  height: 20px;
  margin: 0 15px;
  width: auto;
}

.RecruiterLogin_heading__2HwhH {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 0;
}

.RecruiterLogin_form__2dqcg {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media all and (min-width: 1200px) {
  .RecruiterLogin_Login__f3TvI {
    margin: 10% 33%;
    border-radius: 0.6rem;
    padding: 2rem;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.6);
  }
  .RecruiterLogin_Loginform__wL-9p {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .RecruiterLogin_Loginform__wL-9p:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .RecruiterLogin_showPasswordBtn__1YAs_ {
    -webkit-transform: translate(81%, -165%);
            transform: translate(81%, -165%);
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .RecruiterLogin_Login__f3TvI {
    margin: 20% 20%;
    border-radius: 0.6rem;
    padding: 2rem;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .RecruiterLogin_Loginform__wL-9p {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .RecruiterLogin_Loginform__wL-9p:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .RecruiterLogin_showPasswordBtn__1YAs_ {
    -webkit-transform: translate(81%, -165%);
            transform: translate(81%, -165%);
  }
}

@media (max-width: 700px) {
  .RecruiterLogin_Login__f3TvI {
    margin: 40% 3%;
    border-radius: 0.6rem;
    padding: 5%;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .RecruiterLogin_Loginform__wL-9p {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .RecruiterLogin_Loginform__wL-9p:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .RecruiterLogin_showPasswordBtn__1YAs_ {
    -webkit-transform: translate(79%, -145%);
            transform: translate(79%, -145%);
  }
}

.RecruiterLogin_loginButton__JwEU5 {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #012970;
  color: #fff;
  background-color: #012970;
  cursor: pointer;
}

.RecruiterLogin_loginButton__JwEU5:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.RecruiterLogin_registerButton__1K2Hr {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #012970;
  color: #012970;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}

.RecruiterLogin_registerButton__1K2Hr:hover {
  background-color: #012970;
  color: #fff;
}

.RecruiterLogin_error__3gQcL {
  position: absolute;
  font-size: medium;
  color: orangered;
}

.RecruiterLogin_buttons__Ophtl > hr {
  width: 80%;
  margin-top: 1.5rem;
  border-top: 0.5px solid#b4b4b4;
}

.RecruiterLogin_buttons__Ophtl > p {
  color: #7c7c7c;
  width: 3rem;
  margin: -1.2rem auto 2rem;
  background: #fff;
}

.RecruiterLogin_googleSignIn__1sxtd {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #4285f4;
  color: #4285f4;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
}

.RecruiterLogin_googleButton__1udMf {
  margin: 0 0.6rem 0 0;
  width: 1rem;
  height: 1rem;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 60px 20px 90px;
  color: #2f2f2f;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}

.footer > * {
  flex: 1 1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
  line-height: 1.6;
}

.footer__logo {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 1.5rem;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  font-weight: 400;
  font-size: 15px;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: -webkit-max-content;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0 0.6em -0.6em;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 1 50%;
  margin-right: 1.25em;
}

.nav__item {
  margin-bottom: 0.6rem;
}

.nav__ul a {
  color: #999;
}

.nav__ul--extra {
  -webkit-column-count: 2;
          column-count: 2;
  grid-column-gap: 1.25em;
  -webkit-column-gap: 1.25em;
          column-gap: 1.25em;
}

.legal {
  display: flex;
  flex-wrap: wrap;
  color: #999;
}

.legal__links {
  display: flex;
  align-items: center;
}

.heart {
  color: #2f2f2f;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1 1;
  }

  .nav__item--extra {
    flex-grow: 2;
  }

  .footer__addr {
    flex: 1 1;
  }

  .footer__nav {
    flex: 2 1;
  }
}

.main {
  display: absolute;
  /* margin-top: 200px; */
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sector {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-bottom: 40px;
  background-color: rgba(232, 232, 232, 0.275);
  border-radius: 5px;
}

